.login{
	min-height: 100vh;
	width:100%;
	min-width: 500px;
	background-color: #FFF;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login-form{
	width:500px;
}